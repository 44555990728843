import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Tooltip } from "@mui/material";
import { Button, Card } from "react-bootstrap";
import { KTCard, KTIcon } from "../../../_metronic/helpers";
import { charValidate } from "../../../components/utility";
import MuiTable from "../../../components/table/MuiTable";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { GridColDef } from "@mui/x-data-grid";
import CustomTooltip from "../../../_metronic/layout/components/common/CustomTooltip";
import { useMutation } from "react-query";
import { getAllProductList, deleteProduct } from "../../../api";
import useDebounce from "../../../hooks/useDebounce";
import { APP_URLs } from "../../../constants/appURLs";
import errorFishImageJpg from '../../../_metronic/assets/errorFishImage.jpg'
import AddEditProduct from "./AddEditProduct";
import { ta } from "date-fns/locale";
import ConfirmEventPopup from "../../../_metronic/layout/components/common/ConfirmEventPopup";
import { snackActions } from "../../../helpers/SnackUtilsConfigurator";

const Product: React.FC = () => {
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: "",
  });

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();

  const tableRef = useRef();
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);

  const {
    mutateAsync: getAllProductListAPI,
    data: productData,
    isLoading: isLoading,
  } = useMutation("all-product-list", getAllProductList);

  const { mutateAsync: deleteProductAPI, isLoading: isDeleting } =
  useMutation("delete-product", deleteProduct);


  useEffect(() => {
    getAllProduct(tableOptions);
  }, []);

  useEffect(() => {
    getAllProduct({
      ...tableOptions,
      search: debounceSearchValue ? debounceSearchValue : "",
    });
  }, [debounceSearchValue]);

  const getAllProduct = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
        }
      | undefined
  ) => {
    let response = await getAllProductListAPI(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };
  
  const handleImageError = (event : any) => {
    event.target.src = errorFishImageJpg;
  };

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "Image",
      flex: 0.3,
      sortable: false,
      renderCell: (data: any) =>
        (
          <img
            style={{ height: "3rem", width: "3rem" }}
            src={`${APP_URLs.awsS3ImageURL}${data?.row?.image}`}
            onError={handleImageError}
          />
        ) ?? "N/A",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => (
        <CustomTooltip
          title={
            data?.row?.name ? (
              <h6 style={{ color: "white" }}>{data?.row?.name}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.name ? charValidate(data?.row?.name, 30) : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => (
        <CustomTooltip
          title={
            data?.row ? (
              <h6 style={{ color: "white" }}>{data?.row?.description}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span
            dangerouslySetInnerHTML={{
              __html: charValidate(data?.row?.description, 30) ?? "N/A",
            }}
          />
        </CustomTooltip>
      ),
    },
    {
      field: "variantCount",
      headerName: "Variant Count",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => (data?.row ? data?.row?.variantCount : "N/A"),
    },
    {
      field: "universalCode",
      headerName: "Universal Code",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => (data?.row?.name ? data?.row?.universalCode : "N/A"),
    },
    {
      field: "CategoryId",
      headerName: "Category Name",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => (data?.row?.name ? data?.row?.CategoryId?.name: "N/A"),
    },
    {
      field: "CompanyId",
      headerName: "Company Name",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => (data?.row?.name ? data?.row?.CompanyId?.name: "N/A"),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.2,
      sortable: false,
      headerAlign: "center",
      align: "right",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Edit">
            <DriveFileRenameOutlineIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowAddEditModal(true);
              }}
              sx={{ fontSize: 22, cursor: "pointer", color : "#07427c" }}
            />
          </Tooltip>
            <Tooltip title="Delete">
              <DeleteSweepIcon
                onClick={() => {
                  setSelectedRow(data.row);
                  setShowDeleteConfirmModal(true);
                }}
                sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
                color="error"
              />
            </Tooltip>
        </Box>
      ),
    },
  ];

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? "",
    };
    getAllProduct(query);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };


  const deleteProductFunc = async () => {
    let response = await deleteProductAPI({ id: selectedRow?._id });
    if (response?.status) {
      snackActions.success(response?.message);
      handleCloseDelete();
      getAllProduct(tableOptions);
    } else {
      snackActions.error(response?.message);
    }
  };

  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    setSelectedRow("");
    if (isSubmit) {
      getAllProduct(tableOptions);
    }
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
    setSelectedRow("");
  };
  

  return (
    <Card className="p-6">
      <>
        <KTCard>
          <MuiTable
            columns={columns}
            isAddEnable={false}
            data={productData?.data?.docs ?? []}
            tableRef={tableRef}
            loading={isLoading}
            isBackEnable={false}
            isRowClickable={true}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
            filters={
              <Grid container spacing={2} pb={3}>
                <Grid item lg={3} md={4} xs={12}>
                  <Box>
                    <label
                      className="form-label fw-bold"
                      style={{ marginLeft: 5 }}
                    >
                      Search By Name
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Search by name"
                      aria-label="Search"
                      onChange={(e: any) =>
                        handleChangeFilters("search", e?.target?.value)
                      }
                      value={tableOptions?.search}
                    />
                  </Box>
                </Grid>
                <div className="ms-auto" style={{ marginTop: 43 }}>
                  <Button
                    className="d-flex align-items-center"
                    onClick={() => setShowAddEditModal(true)}
                  >
                    <KTIcon iconName="plus" className="fs-1" />
                    Add Product
                  </Button>
                </div>
              </Grid>
            }
          />
        </KTCard>
        {showAddEditModal && (
          <AddEditProduct
            editData={selectedRow}
            show={showAddEditModal}
            handleClose={handleModalClose}
          />
        )}
        {showDeleteConfirmModal && (
          <ConfirmEventPopup
            showPopup={showDeleteConfirmModal}
            handleClose={handleCloseDelete}
            handleConfirmed={deleteProductFunc}
            loading={isDeleting}
            title={"Delete Product"}
            message={"Are you sure, want to delete this product?"}
          />
        )}
      </>
    </Card>
  );
};

export default Product;
