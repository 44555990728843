import { Button, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "react-query";
import { addEditWaters, getCountries, getStates } from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import {
  prevStep,
  resetStep,
  setWaterFormData,
} from "../../store/slice/form/watersForm";
import { useEffect, useState } from "react";

const Step_2 = ({ handleClose, editData }: any) => {
  const [countryNameonSelection, setCountryNameonSelection] = useState("");
  const [stateNameonSelection, setStateNameonSelection] = useState("");

  const { mutateAsync: addEditWatersAPI, isLoading: isLoading } = useMutation(
    "add-waters",
    addEditWaters
  );

  const { data: countryDataList } = useQuery("get-country", getCountries);
  const countryList = countryDataList?.data;
  const { mutateAsync: getStateAPI, data: stateData } = useMutation(
    "get-states",
    getStates
  );

  const Step_2_validation_schema = Yup.object().shape({
    country: Yup.string().required("This field is required"),
    state: Yup.string().required("This field is required"),
    latitude: Yup.number()
      .required("Latitude is required")
      .min(-90, "Latitude must be between -90 and 90")
      .max(90, "Latitude must be between -90 and 90"),
    longitude: Yup.number()
      .required("Longitude is required")
      .min(-180, "Longitude must be between -180 and 180")
      .max(180, "Longitude must be between -180 and 180"),
  });
  const dispatch = useDispatch();

  const formDatas = useSelector((state: any) => state?.waters?.waterFormData);

  const formik: any = useFormik({
    initialValues: {
      country: formDatas?.CountryId || "",
      state: formDatas?.StateId || "",
      latitude: formDatas?.Latitude || "",
      longitude: formDatas?.Longitude || "",
    },
    validationSchema: Step_2_validation_schema,
    onSubmit: async (values) => {
      dispatch(setWaterFormData(values));
      const bodyParams = {
        id: editData?._id ? editData?._id : undefined,
        name: formDatas?.name ?? "",
        image: formDatas?.image ?? "",
        type: formDatas?.type ?? "",
        Area: formDatas?.area ?? "",
        maxLength: formDatas?.maxLength ?? "",
        maxWidth: formDatas?.maxWidth ?? "",
        maxDepth: formDatas?.maxDepth ?? "",
        surfaceArea: formDatas?.surfaceArea ?? "",
        surfaceElevation: formDatas?.surfaceElevation ?? "",
        AreaMeasure: formDatas?.surfaceAreaMeasure ?? "",
        maxLengthMeasure: formDatas?.maxLengthMeasure ?? "",
        maxWidthMeasure: formDatas?.maxWidthMeasure ?? "",
        maxDepthMeasure: formDatas?.maxDepthMeasure ?? "",
        surfaceAreaMeasure: formDatas?.surfaceAreaMeasure ?? "",
        surfaceElevationMeasure: formDatas?.surfaceElevationMeasure ?? "",
        CountryId: values?.country ?? "",
        Country: countryNameonSelection ?? "",
        Region: [],
        StateId: values?.state ?? "",
        State: stateNameonSelection ?? "",
        Latitude: values?.latitude ?? "",
        Longitude: values?.longitude ?? "",
      };
      let response = await addEditWatersAPI(bodyParams);
      if (response?.status) {
        snackActions.success(
          response?.message ?? "Form submitted successfully!"
        );
        handleClose(true);
        dispatch(resetStep());
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const CountryId = selectedOption.value;
    const countryName = selectedOption.text;
    formik.setFieldValue("country", CountryId);
    setCountryNameonSelection(countryName);
  };

  const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const StateId = selectedOption.value;
    const stateName = selectedOption.text;
    formik.setFieldValue("state", StateId);
    setStateNameonSelection(stateName);
  };

  useEffect(() => {
    if (formik.values.country) {
      getStateAPI(formik.values.country);
    }
  }, [formik.values.country, getStateAPI]);
  useEffect(() => {
    if (formDatas?.CountryId) {
      setCountryNameonSelection(countryList?.find(
        (country: any) => country.countryId === formDatas.CountryId
      )?.name || "");
      getStateAPI(formDatas.CountryId);
    }
  }, [formDatas, countryList, getStateAPI]);
  
  useEffect(() => {
    if (formDatas?.StateId && stateData?.data) {
      setStateNameonSelection(stateData.data.find(
        (state: any) => state.id === formDatas.StateId
      )?.name || "");
    }
  }, [formDatas, stateData]);
  return (
    <form onSubmit={formik.handleSubmit} noValidate className="form">
      <div className="p-7">
        <Row>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">Select Country<span className="red-asterisk">*</span></label>
            <select
              className="form-control form-control-lg form-control-solid"
              {...formik.getFieldProps("country")}
              onChange={handleCountryChange}
              value={formik.values.country}  // Add this line
            >
              <option>Select Country</option>
              {countryList?.map((country: any, index: number) => {
                return (
                  <option key={index} value={country?.countryId}>
                    {country?.name}
                  </option>
                );
              })}
            </select>
            {formik.touched.country && formik.errors.country && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.country}</div>
              </div>
            )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">Select State<span className="red-asterisk">*</span></label>
            <select
              className="form-control form-control-lg form-control-solid"
              {...formik.getFieldProps("state")}
              onChange={handleStateChange}
              value={formik.values.state}  // Add this line
            >
              <option>Select State</option>
              {stateData?.data?.map((state: any, index: number) => (
                <option key={index} value={state?.id}>
                  {state?.name}
                </option>
              ))}
            </select>
            {formik.touched.state && formik.errors.state && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.state}</div>
              </div>
            )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">Enter Region Latitude<span className="red-asterisk">*</span></label>
            <input
              type="number"
              className="form-control form-control-lg form-control-solid"
              placeholder="Region Latitude"
              aria-label="Name"
              {...formik.getFieldProps("latitude")}
            />
            {formik.touched.latitude && formik.errors.latitude && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.latitude}</div>
              </div>
            )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter Region Longitude<span className="red-asterisk">*</span>
            </label>
            <input
              type="number"
              className="form-control form-control-lg form-control-solid"
              placeholder="Region Longitude"
              {...formik.getFieldProps("longitude")}
            />
            {formik.touched.longitude && formik.errors.longitude && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.longitude}</div>
              </div>
            )}
          </Col>
        </Row>
      </div>
      <div
        className="modal-footer"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          onClick={() => dispatch(prevStep())}
          size="lg"
          variant="secondary"
        >
          Back
        </Button>
        <Button className="ms-3" type="submit" size="lg" variant="primary">
          {isLoading ? "Loading..." : "Submit"}
        </Button>
      </div>
    </form>
  );
};

export default Step_2;
