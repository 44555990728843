/* eslint-disable react/jsx-no-target-blank */
import { isSuperAdmin } from "../../../../../hooks/useUserInfo";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { ReactComponent as DashboardIcon } from "../../../../assets/svg/dashboard.svg"
import { ReactComponent as GeardIcon } from "../../../../assets/svg/gear.svg"
import { ReactComponent as BaitIcon } from "../../../../assets/svg/dashboard.svg"
import { ReactComponent as SpeciesIcon } from "../../../../assets/svg/species.svg"
import { ReactComponent as WatersIcon } from "../../../../assets/svg/waters.svg"
import { ReactComponent as ReportedIcon } from "../../../../assets/svg/reported.svg"
import { ReactComponent as SettingsIcon } from "../../../../assets/svg/settings.svg"
import { ReactComponent as WarningIcon } from "../../../../assets/svg/warning.svg"


const SidebarMenuMain = () => {
  interface ISideBarChildMenu {
    path: string;
    title: string;
    child: [];
    hasBullet: boolean;
  }
  interface ISideBarMenu {
    path: string;
    title: string;
    icon: any;
    fontIcon: string;
    child: ISideBarChildMenu[] | [];
    hasBullet: boolean;
    isSuperAdmin: boolean;
    isAdmin: boolean;
  }
  const sideBarMenus: ISideBarMenu[] = [
    {
      path: "/dashboard",
      icon: <DashboardIcon />,
      title: "Dashboard",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/gears",
      icon: <GeardIcon />,
      title: "Gear",
      fontIcon: "bi-layers",
      child: [
        {
          path: "/gear-category",
          title: "Category",
          child: [],
          hasBullet: true,
        },
        {
          path: "/gear-company",
          title: "Company",
          child: [],
          hasBullet: true,
        },
        {
          path: "/gear-product",
          title: "Product",
          child: [],
          hasBullet: true,
        },
      ],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/baits",
      icon: <BaitIcon />,
      title: "Bait",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/species",
      icon: <SpeciesIcon />,
      title: "Species",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/annotated-images",
      icon: <SpeciesIcon />,
      title: "Annotated Images",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/unlabelled-waters",
      icon: <WarningIcon />,
      title: "Unlabelled Waters",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/waters",
      icon: <WatersIcon />,
      title: "Waters",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/report",
      icon: <ReportedIcon />,
      title: "Reported Content",
      fontIcon: "bi-layers",
      child: [
        {
          path: "/users",
          title: "User",
          child: [],
          hasBullet: true,
        },
        {
          path: "/post",
          title: "Post",
          child: [],
          hasBullet: true,
        },
        {
          path: "/feedback",
          title: "Feedback",
          child: [],
          hasBullet: true,
        },
      ],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/settings",
      icon: <SettingsIcon />,
      title: "Settings",
      fontIcon: "bi-layers",
      child: [
        {
          path: "/about-app",
          title: "About App",
          child: [],
          hasBullet: true,
        },
        {
          path: "/rules-regulation",
          title: "Rules and Regulations",
          child: [],
          hasBullet: true,
        },
        {
          path: "/terms-condition",
          title: "Terms and Condition",
          child: [],
          hasBullet: true,
        },
        {
          path: "/privacy-policy",
          title: "Privacy Policy",
          child: [],
          hasBullet: true,
        },
        {
          path: "/copyright-policy",
          title: "Copyright Policy",
          child: [],
          hasBullet: true,
        },
        {
          path: "/feedback",
          title: "Feedback",
          child: [],
          hasBullet: true,
        },
        {
          path: "/contact-us",
          title: "Contact Us",
          child: [],
          hasBullet: true,
        },
      ],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/tflite-models",
      icon: <WatersIcon />,
      title: "TFLite models",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
  ];

  return (
    <>
      {sideBarMenus.map((menu: ISideBarMenu) => {
        return menu.isSuperAdmin || menu.isAdmin ? (
          <div key={menu.title}>
            {menu.child.length ? (
              <SidebarMenuItemWithSub
                to={menu.path}
                title={menu.title}
                hasBullet={menu.hasBullet}
                fontIcon={menu.fontIcon}
                icon={menu.icon}
              >
                {menu.child.map((menuChild: ISideBarChildMenu) => (
                  <SidebarMenuItem
                    key={menu.title}
                    to={menuChild.path}
                    title={menuChild.title}
                    hasBullet={menuChild.hasBullet}
                  />
                ))}
              </SidebarMenuItemWithSub>
            ) : (
              <SidebarMenuItem
                to={menu.path}
                title={menu.title}
                hasBullet={menu.hasBullet}
                fontIcon={menu.fontIcon}
                icon={menu.icon}
              />
            )}
          </div>
        ) : null;
      })}
    </>
  );
};

export { SidebarMenuMain };
