import { Box } from "@mui/material";
import { Button, Col, Modal, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { KTIcon } from "../../../_metronic/helpers";

export type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  editData: any;
};

const FeedbackInfoPopUp = ({ show, handleClose, editData }: Props) => {
  
  const handleCancel = () => {
    handleClose(false);
  };

  return (
    <Box>
      <Modal
        id="kt_modal_create_app"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-700px"
        show={show}
        backdrop="static"
      >
        <div className="modal-header">
          <h2>Feedback Info</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={handleCancel}
          >
            <KTIcon className="fs-1" iconName="cross" />
          </div>
        </div>

        <div className="p-6" style={{ overflowY: "auto" }}>
          <form
            onSubmit={() => console.log("form data")}
            noValidate
            className="form"
          >
            <Row className="mb-6">
              <Col xs={6}>
                <label className="form-label fw-bold">First Name</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="First name"
                  value={editData?.UserId?.firstName ?? "N/A"}
                  readOnly
                />
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">Last Name</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-5 mb-lg-0"
                  placeholder="Last name"
                  value={editData?.UserId?.lastName ?? "N/A"}
                  readOnly
                />
              </Col>
            </Row>
            <Row className="mb-6">
              <Col xs={12}>
              <label className="form-label fw-bold">Type</label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid mb-5 mb-lg-0"
                placeholder="Type"
                value={editData?.feedbackType ?? "N/A"}
                readOnly
              />
              </Col>
            </Row>
            <div className="mb-6">
              <label className="form-label fw-bold">Description</label>
              <ReactQuill
                theme="snow"
                placeholder="Write something..."
                value={editData?.feedbackContent ?? "N/A"}
                modules={FeedbackInfoPopUp.modules}
                formats={FeedbackInfoPopUp.formats}
                readOnly
              />
            </div>
            <div className="w-100 d-flex align-items-center justify-content-end mt-5">
              <Button onClick={handleCancel} size="lg" variant="secondary">
                Close
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </Box>
  );
};

export default FeedbackInfoPopUp;

FeedbackInfoPopUp.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: [] }],
  ],
};
FeedbackInfoPopUp.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];
