import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { charValidate } from "../../components/utility";
import { uploadMultiPicture, uploadPicture } from "../../api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  nextStep,
  setSpeciesFormData,
} from "../../store/slice/form/speciesForm";
import { APP_URLs } from "../../constants/appURLs";
import CryptoJS from 'crypto-js';

interface DecryptedResponseData {
  images?: string;
  status?: boolean;
  message?: string;
}

// Define form values interface
interface FormValues {
  name: string;
  scientificName: string;
  description: string;
  image: string[];
}

const decryptPayload = (ciphertext: any, secretKey: any, iv: any) => {
  const key = CryptoJS.enc.Base64.parse(secretKey);
  const ivParsed = CryptoJS.enc.Base64.parse(iv);
  const decrypted = CryptoJS.AES.decrypt(ciphertext, key, { iv: ivParsed }).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decrypted);
};

const Step_0 = ({ handleClose, editData }: any) => {
  const formDatas: any = useSelector((state: any) => state?.species?.formData);
  const [imgUploading, setImgUploading] = useState<boolean>(false);

  const initialImages = editData?.image ? 
    (Array.isArray(editData.image) ? editData.image : [editData.image]) : 
    [];

  const [speciesImg, setSpeciesImg] = useState<string[]>(formDatas?.image || initialImages);

  const [imgFileNames, setImgFileNames] = useState<string[]>(
    initialImages.map((img: string) => img.split("/img/")[1] || "")
  );

  const Step_0_validation_schema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    scientificName: Yup.string().trim().required("Scientific Name is required"),
    description: Yup.string().trim().required("Description is required"),
    image: Yup.array().min(1, "At least one image is required"),
  });

  const dispatch = useDispatch();

  const formik = useFormik<FormValues>({
    initialValues: {
      name: formDatas?.name || "",
      scientificName: formDatas?.scientificName || "",
      image: formDatas?.image || initialImages,
      description: formDatas?.description || "",
    },
    validationSchema: Step_0_validation_schema,
    onSubmit: async (values, { setFieldError }) => {
      if (speciesImg.length === 0) {
        setFieldError("image", "At least one image is required");
        return;
      }
      dispatch(setSpeciesFormData({ ...values, image: speciesImg }));
      dispatch(nextStep());
    },
  });

  // Helper function to safely render form errors
  const renderError = (error: unknown) => {
    if (typeof error === 'string') {
      return <div className="fv-help-block">{error}</div>;
    }
    return null;
  };

  const handleChangeImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    let formData = new FormData();
    formData.append("photo", file);

    try {
      let response = await uploadPicture(formData);
      
      if (response) {
        let decryptedResponseData: DecryptedResponseData = {};
        if (response.data) {
          decryptedResponseData = decryptPayload(
            response?.data,
            process.env.REACT_APP_SECRET_KEY,
            process.env.REACT_APP_IV
          ) as DecryptedResponseData;
        }

        if (response?.status && decryptedResponseData.images) {
          const updatedImages = [...speciesImg, decryptedResponseData.images];
          setSpeciesImg(updatedImages);
          formik.setFieldValue("image", updatedImages);
          setImgFileNames([...imgFileNames, file.name]);
        } else {
          snackActions.error(
            response?.message ?? "Issue in image upload, try again later!"
          );
        }
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      snackActions.error("Error uploading image, try again later!");
    }
  };

  const removeImg = (index: number) => {
    const updatedImages = speciesImg.filter((_, i) => i !== index);
    const updatedFileNames = imgFileNames.filter((_, i) => i !== index);
    
    setSpeciesImg(updatedImages);
    setImgFileNames(updatedFileNames);
    formik.setFieldValue("image", updatedImages);
    
    if (updatedImages.length === 0) {
      formik.setFieldError("image", "At least one image is required");
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate className="form">
      <div className="p-7">
        <Row>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter Name<span className="red-asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Name"
              aria-label="Name"
              {...formik.getFieldProps("name")}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="fv-plugins-message-container">
                {renderError(formik.errors.name)}
              </div>
            )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter Scientific Name<span className="red-asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Scientific Name"
              {...formik.getFieldProps("scientificName")}
            />
            {formik.touched.scientificName && formik.errors.scientificName && (
              <div className="fv-plugins-message-container">
                {renderError(formik.errors.scientificName)}
              </div>
            )}
          </Col>
          <Col sm={12} className="mb-4">
            <label className="form-label fw-bold">
              Enter Description<span className="red-asterisk">*</span>
            </label>
            <ReactQuill
              theme="snow"
              placeholder="Write something..."
              value={formik.values.description}
              modules={Step_0.modules}
              formats={Step_0.formats}
              onChange={(content) => formik.setFieldValue("description", content)}
            />
            {formik.touched.description && formik.errors.description && (
              <div className="fv-plugins-message-container">
                {renderError(formik.errors.description)}
              </div>
            )}
          </Col>
          <Col sm={12} className="mb-4">
            <label className="form-label fw-bold">
              Image upload<span className="red-asterisk">*</span>
            </label>
            <div>
              <label
                htmlFor="company-img"
                className="form-control form-control-lg form-control-solid cursor-pointer"
              >
                Choose File
              </label>
              <input
                id="company-img"
                type="file"
                multiple
                accept="image/*"
                className="form-control form-control-lg form-control-solid"
                placeholder="Upload Image"
                aria-label="Upload Image"
                onChange={handleChangeImageUpload}
                style={{ display: "none" }}
              />
              {formik.touched.image && formik.errors.image && (
                <div className="fv-plugins-message-container">
                  {renderError(formik.errors.image)}
                </div>
              )}
            </div>
            <div className="mt-4">
              <Row>
                {speciesImg.map((img, index) => (
                  <Col key={index} sm={6} md={4} className="mb-3">
                    <div className="position-relative border p-2">
                      <img
                        src={`${APP_URLs.awsS3ImageURL}${img}`}
                        className="w-100"
                        alt={`Preview ${index + 1}`}
                      />
                      <CloseIcon
                        className="position-absolute top-0 end-0 m-2 bg-light rounded-circle cursor-pointer"
                        style={{
                          height: "24px",
                          width: "24px",
                          cursor: "pointer",
                        }}
                        onClick={() => removeImg(index)}
                      />
                      <small className="d-block mt-1 text-muted">
                        {charValidate(imgFileNames[index] || "", 25)}
                      </small>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div
        className="modal-footer"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          onClick={handleClose}
          size="lg"
          variant="secondary"
          disabled={imgUploading}
        >
          Close
        </Button>
        <Button
          className="ms-3"
          type="submit"
          size="lg"
          variant="primary"
          disabled={imgUploading}
        >
          Save & Next
        </Button>
      </div>
    </form>
  );
};

export default Step_0;

Step_0.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: [] }],
  ],
};

Step_0.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];