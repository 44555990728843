import React, { useState } from "react";
import { Box } from "@mui/material";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { KTIcon } from "../../../_metronic/helpers";
import * as Yup from "yup";
import { useFormik } from "formik";
import { charValidate } from "../../../components/utility";
import { addProduct, getAllCategoryList, getAllCompanyList, uploadPicture } from "../../../api";
import { snackActions } from "../../../helpers/SnackUtilsConfigurator";
import { useMutation, useQuery } from "react-query";
import { APP_URLs } from "../../../constants/appURLs";
import CloseIcon from '@mui/icons-material/Close';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const AddEditProduct = ({ show, handleClose, editData }) => {
  const [imgUploading, setImgUploading] = useState(false);

  let imgUrl = editData?.image
    ? editData?.image[0]
      ? editData?.image[0]
      : ""
    : "";

  const [productImg, setCompanyImg] = useState("");

  const [imgFileName, setImgFileName] = useState(
    imgUrl ? imgUrl?.split("/img/")[1] : ""
  );

  const companyValidationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    image: Yup.string().required('Image is required'),
    description: Yup.string().required("Description is required"),
    universalCode: Yup.string().required("Universal code is required"),
    CategoryId: Yup.string().required("Category is required"),
    CompanyId: Yup.string().required("Company is required"),
  });

  const { data: companyData } = useQuery("company", getAllCompanyList);
  const { data: categoryData } = useQuery("category", getAllCategoryList);

  const { mutateAsync: addProductAPI, isLoading: isLoading } = useMutation("add-product", addProduct)

  const formik = useFormik({
    initialValues: {
      name: editData ? editData?.name : "",
      image: editData ? editData?.image : "",
      description: editData?.description ?? "",
      universalCode: editData?.universalCode ?? "",
      CategoryId: editData && editData.CategoryId ? editData.CategoryId._id : "",
      CompanyId: editData && editData.CompanyId ? editData.CompanyId._id : ""
    },

    validationSchema: companyValidationSchema,
    onSubmit: async (values, { setFieldError }) => {
      if (!productImg && !editData?.image) {
        setFieldError('image', 'Image is required');
        return;
      }
      let bodyParams = {
        id: editData ? editData?._id : undefined,
        name: values?.name ? values?.name?.replace(/^\s+|\s+$/g, "") : "",
        image: productImg || (editData && editData.image) || null,
        universalCode: values?.universalCode ? values?.universalCode?.replace(/^\s+|\s+$/g, "") : "",
        description: values?.description ? values?.description : "",
        CategoryId: values?.CategoryId ? values?.CategoryId : "",
        CompanyId: values?.CompanyId ? values?.CompanyId : "",
      };
      let response = await addProductAPI(bodyParams);
      if (response?.status) {
        snackActions.success(response?.message ?? "Company Added Successfully!");
        formik.resetForm();
        setImgFileName("");
        setCompanyImg("");
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });

  const handleChangeImageUpload = async (e) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setImgFileName(file?.name ?? "");
    formData.append("photo", file);
    if (file) {
      setImgUploading(true);
      let response = await uploadPicture(formData);
      if (response?.status) {
        setCompanyImg(response?.data?.images);
        formik.setFieldValue('image', response?.data?.images);
        setImgUploading(false);
      } else {
        snackActions.error(
          response?.message ?? "Issue in image upload, try again latter!"
        );
        setImgUploading(false);
      }
    }
  };

  const removeImg = () => {
    formik.setFieldValue('image', '');
    formik.setFieldError('image', null);
    setCompanyImg("");
    if (editData) {
      editData.image = "";
    }
    setImgFileName("");
  };

  return (
    <Box>
      <Modal
        id="kt_modal_create_app"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-700px"
        show={show}
        backdrop="static"
      >
        <div className="modal-header">
          <h2> {editData ? "Edit" : "Add"} Product</h2>
          {/* begin::Close */}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              setImgFileName("");
              setCompanyImg("");
              handleClose(false);
            }}
          >
            <KTIcon className="fs-1" iconName="cross" />
          </div>
          {/* end::Close */}
        </div>

        <div className="p-6" style={{ overflowY: "auto" }}>
          <form onSubmit={formik.handleSubmit} noValidate className="form">
            <Row>
              <Col xs={6}>
                <label className="form-label fw-bold">Name<span className="red-asterisk">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Name"
                  aria-label="Name"
                  {...formik.getFieldProps("name")}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">Enter Universal Code<span className="red-asterisk">*</span></label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Universal code"
                  aria-label="Name"
                  {...formik.getFieldProps("universalCode")}
                />
                {formik.touched.universalCode && formik.errors.universalCode && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.universalCode}</div>
                  </div>
                )}
              </Col>
              <Row className="mb-6">
                <Col xs={6}>
                  <label className="form-label fw-bold mt-5">Select Company<span className="red-asterisk">*</span></label>
                  <select name="CompanyId" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    {...formik.getFieldProps("CompanyId")}
                  >
                    <option>Select Company</option>
                    {companyData?.data?.docs.map((item) => {
                      return <option key={item._id} value={item._id}>{item?.name}</option>
                    })}
                  </select>
                  {formik.touched.CompanyId && formik.errors.CompanyId && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.CompanyId}</div>
                    </div>
                  )}
                </Col>
                <Col xs={6}>
                  <label className="form-label fw-bold mt-5">Select Category<span className="red-asterisk">*</span></label>
                  <select name="CategoryId" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    {...formik.getFieldProps("CategoryId")}
                  >
                    <option>Select Category</option>
                    {categoryData?.data?.docs?.map((item) => {
                      return <option key={item?._id} value={item?._id}>{item?.name}</option>
                    })}
                  </select>
                  {formik.touched.CategoryId && formik.errors.CategoryId && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.CategoryId}</div>
                    </div>
                  )}
                </Col>
              </Row>
              <div className="mb-6">
                <label className="form-label fw-bold">Enter Description<span className="red-asterisk">*</span></label>
                <ReactQuill
                  theme="snow"
                  placeholder="Write something..."
                  value={formik.values.description}
                  modules={AddEditProduct.modules}
                  formats={AddEditProduct.formats}
                  onChange={(content) =>
                    formik.setFieldValue("description", content)
                  }
                />
                {formik.touched.description && formik.errors.description && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.description}</div>
                  </div>
                )}
              </div>
              <div className="mb-6">
                <label className="form-label fw-bold">Image upload<span className="red-asterisk">*</span></label>
                <div>
                  <label
                    htmlFor="product-img"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer"
                  >
                    {imgFileName
                      ? charValidate(imgFileName, 25)
                      : "Choose File"}
                  </label>
                  <input
                    id="product-img"
                    type="file"
                    accept="image/*"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Upload Image"
                    aria-label="Upload Image"
                    onChange={handleChangeImageUpload}
                    style={{
                      display: "none",
                    }}
                  />
                  {formik.touched.image && formik.errors.image && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.image}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-6">
                {imgUploading && (
                  <div className="d-flex justify-content-center m-5">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
                {(productImg || editData?.image) && (
                  <div style={{
                    textAlign: "center",
                    marginTop: "1rem",
                    position: "relative",
                  }}>
                    <img
                      src={`${APP_URLs.awsS3ImageURL}${productImg || editData?.image}`}
                      style={{
                        maxWidth: "200px",
                        margin: "0 auto",
                        padding: "1rem",
                        border: "1px solid #eeeeee",
                      }}
                      alt="Preview"
                    />
                    <CloseIcon
                      className="close-icon"
                      onClick={removeImg}
                    />
                  </div>
                )}
              </div>
            </Row>
            <div className="w-100 d-flex align-items-center justify-content-end mt-5">
              <Button
                onClick={() => {
                  formik.resetForm();
                  setImgFileName("");
                  setCompanyImg("");
                  handleClose(false);
                }}
                size="lg"
                variant="secondary"
                disabled={imgUploading}
              >
                Cancel
              </Button>
              <div className="w-15px"></div>
              <Button type="submit" size="lg" variant="primary"  disabled={imgUploading}>
                {isLoading ? "Loading..." : "Submit"}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </Box>
  );
};

export default AddEditProduct;

AddEditProduct.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: [] }],
  ],
};
AddEditProduct.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];
