import React, { useEffect, useState } from "react";
import { Card, Row, Col, Spinner, Pagination, OverlayTrigger, Tooltip, Form, Button } from "react-bootstrap"; 
import { useMutation } from "react-query";
import { getAllSpeciesList } from "../../api";
import useDebounce from "../../hooks/useDebounce";
import { APP_URLs } from "../../constants/appURLs";
import { charValidate } from "../../components/utility";
import FishImg from "../../_metronic/assets/errorFishImage.jpg";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const AnnotatedImages: React.FC = () => {
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: 0,
    search: "",
  });

  // Debounced search value
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);
  const {
    mutateAsync: getSpeciesListAPI,
    data: speciesData,
    isLoading: isLoading,
  } = useMutation("species-list", getAllSpeciesList);

  const navigate = useNavigate(); // Initialize useNavigate

  // Fetch species on initial load and when search term changes
  useEffect(() => {
    getAllSpecies(tableOptions);
  }, []);

  useEffect(() => {
    getAllSpecies({
      ...tableOptions,
      search: debounceSearchValue ? debounceSearchValue : "",
    });
  }, [debounceSearchValue]);

  // API call to get species
  const getAllSpecies = async (query: { offset: number | string; limit: number; search: string; } | undefined) => {
    let response = await getSpeciesListAPI(query);
    if (response?.status) {
      setTableOptions((prevState: any) => ({
        ...prevState,
        offset: response?.data?.offset,
        limit: response?.data?.limit,
        hasNextPage: response?.data?.hasNextPage,
        hasPrevPage: response?.data?.hasPrevPage,
        totalRowCount: response?.data?.totalDocs,
      }));
    }
  };

  // Handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTableOptions({
      ...tableOptions,
      search: e.target.value,
    });
  };

  // Handle image load error fallback
  const handleImageError = (event: any) => {
    event.target.src = FishImg;
  };

  // Handle pagination click
  const handlePaginationClick = (page: number) => {
    const newOffset = (page - 1) * tableOptions.limit;
    getAllSpecies({
      offset: newOffset,
      limit: tableOptions.limit,
      search: debounceSearchValue,
    });
  };

  // Handle image click
  const handleImageClick = (id: string, speciesName: string) => {
    localStorage.setItem("selectedSpeciesName", speciesName);
    navigate(`/details-annotated-image/${id}`);
  };

  const totalPages = Math.ceil(tableOptions.totalRowCount / tableOptions.limit);
  const currentPage = Math.floor(tableOptions.offset / tableOptions.limit) + 1;

  const getPaginationItems = () => {
    const items = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    if (startPage > 1) {
      items.push(
        <Pagination.First key="first" onClick={() => handlePaginationClick(1)} />
      );
      items.push(
        <Pagination.Prev
          key="prev"
          onClick={() => handlePaginationClick(currentPage - 1)}
          disabled={!tableOptions.hasPrevPage}
        />
      );
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePaginationClick(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      items.push(
        <Pagination.Next
          key="next"
          onClick={() => handlePaginationClick(currentPage + 1)}
          disabled={!tableOptions.hasNextPage}
        />
      );
      items.push(
        <Pagination.Last key="last" onClick={() => handlePaginationClick(totalPages)} />
      );
    }

    return items;
  };

  return (
    <Card className="p-6">
      {isLoading ? (
        <Spinner animation="border" />
      ) : (
        <>
          {/* Add a search bar */}
          <Form className="mb-4">
            <Form.Group controlId="searchSpecies">
              <Form.Control
                type="text"
                placeholder="Search annotated images..."
                value={tableOptions.search}
                onChange={handleSearchChange}
                style={{
                  width: "20%",
                  height: "40px",
                  borderRadius: "10px",
                  borderColor: "#D8D8D8",
                }}
              />
            </Form.Group>
          </Form>

          {/* Dynamically display 4 images per row */}
          <Row xs={1} md={5} lg={5} className="g-4">
            {speciesData?.data?.docs.map((species: any) => (
              <Col key={species.id}>
                <Card>
                  <Card.Img
                    variant="top"
                    src={`${APP_URLs.awsS3ImageURL}${species.image}`}
                    onError={handleImageError}
                    onClick={() => handleImageClick(species._id, species.name)}
                    style={{ height: "200px", objectFit: "cover", cursor: "pointer" }}
                  />
                  <Card.Body>
                    <Card.Title>
                      {charValidate(species.name, 30) || "N/A"}
                    </Card.Title>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-${species.id}`}>{species.description}</Tooltip>}
                    >
                      <Card.Text>
                        {charValidate(species.description, 70) || "N/A"}
                      </Card.Text>
                    </OverlayTrigger>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <Pagination className="mt-4">
            {getPaginationItems()}
          </Pagination>
        </>
      )}
    </Card>
  );
};

export default AnnotatedImages;
