import { Box, CircularProgress } from "@mui/material"; // Import CircularProgress
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { getDashboardList } from "../../api";

const DashboardPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedValue, setSelectedValue] = useState("general"); // Initial selected value

  const {
    mutateAsync: getDashBoardListAPI,
    data: dashBoardData,
    isLoading: isFetching, // Renamed for clarity
  } = useMutation("dashboard-list", getDashboardList);

  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoading(true); // Set loading to true when starting the fetch
      try {
        await getDashBoardListAPI(selectedValue);
      } catch (err) {
        setError("Failed to load data");
      } finally {
        setLoading(false); // Set loading to false when done
      }
    };

    fetchDashboardData();
  }, [selectedValue]);

  // Display loader while fetching
  if (loading || isFetching) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress /> {/* Display a loading spinner */}
      </Box>
    );
  }

  if (error) {
    return <div>{error}</div>; // Display error message
  }

  const options = [
    { value: 'general', label: 'General' },
    { value: 'fishingSpot', label: 'Fishing Spot' },
    { value: 'report', label: 'Report' },
    { value: 'catch', label: 'Catch' },
    { value: 'spot', label: 'Spot' },
  ];

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(event.target.value); // Update the selected value
  };

  return (
    <Box pb={1}>
      <div className="my-5">
        <div className="row g-6">
          <div className="col-lg-4 col-md-4">
            <div className="dashboard-card-01 dashboard-card-box card">
              <div className="card-body p-5 p-md-4">
                <h5>Users</h5>
                <div className="dashboard-card-bottom d-flex align-items-center">
                  <h3>{dashBoardData?.data?.userCount || 0}</h3>
                </div>
                <span className="card-bubble"></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="dashboard-card-02 dashboard-card-box card">
              <div className="card-body p-5 p-md-4">
                <h5>Species</h5>
                <div className="dashboard-card-bottom d-flex align-items-center">
                  <h3>{`${dashBoardData?.data?.speciesCount || 0}`}</h3>
                </div>
                <span className="card-bubble"></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="dashboard-card-03 dashboard-card-box card">
              <div className="card-body p-5 p-md-4">
                <h5>Waters</h5>
                <div className="dashboard-card-bottom d-flex align-items-center">
                  <h3>{`${dashBoardData?.data?.waterCount || 0}`}</h3>
                </div>
                <span className="card-bubble"></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="dashboard-card-04 dashboard-card-box card">
              <div className="card-body p-5 p-md-4">
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="m-0">Total Posts Count</h5>
                  <select className="custom-select" value={selectedValue} onChange={handleSelectChange}>
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="dashboard-card-bottom d-flex align-items-center">
                  <h3>{`${dashBoardData?.data?.postCount || 0}`}</h3>
                </div>
                <span className="card-bubble"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default DashboardPage;
