import { FC } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { App } from "../App";
import { IInitialState } from "../constants/types";
import { AuthPage, Logout } from "../pages/auth";
import { ErrorsPage } from "../pages/errors/ErrorsPage";
import { PrivateRoutes } from "./PrivateRoutes";

const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const state: IInitialState = useSelector((state: IInitialState) => state);
  const pathname = window.location.pathname ?? "";

  if (pathname === "/payment") {
    window.location.href = "/payment.html";
  }

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          {state?.auth?.userToken ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              {/* <Route index element={<Navigate to="/golf-club" />} */}
              <Route index element={<Navigate to="/category" />} />
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth/login" />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
