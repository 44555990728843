import { Button, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  nextStep,
  prevStep,
  setWaterFormData,
} from "../../store/slice/form/unlabelledForm";

const Step_1 = () => {
  const dispatch = useDispatch();

  const formDatas: any = useSelector((state: any) => state?.waters);

  const Step_1_validation_schema = Yup.object().shape({
    maxDepth: Yup.string().trim().required("This field is required"),
    maxDepthMeasure: Yup.string().trim().required("This field is required"),
    maxLength: Yup.string().trim().required("This field is required"),
    maxLengthMeasure: Yup.string().required("This field is required"),
    maxWidth: Yup.string().required("This field is required"),
    maxWidthMeasure: Yup.string().required("This field is required"),
    surfaceArea: Yup.string().required("This field is required"),
    surfaceAreaMeasure: Yup.string().required("This field is required"),
    surfaceElevation: Yup.string().required("This field is required"),
    surfaceElevationMeasure: Yup.string().required("This field is required"),
    area: Yup.string().trim().required("Area is required"),
  });

  const formik: any = useFormik({
    initialValues: {
      maxDepth: formDatas?.maxDepth || "",
      maxDepthMeasure: formDatas?.maxDepthMeasure || "",
      maxLength: formDatas?.maxLength || "",
      maxLengthMeasure: formDatas?.maxLengthMeasure || "",
      maxWidth: formDatas?.maxWidth || "",
      maxWidthMeasure: formDatas?.maxWidthMeasure || "",
      surfaceArea: formDatas?.surfaceArea || "",
      surfaceAreaMeasure: formDatas?.surfaceAreaMeasure || "",
      surfaceElevation: formDatas?.surfaceElevation || "",
      surfaceElevationMeasure: formDatas?.surfaceElevationMeasure || "",
      area: formDatas?.area || "",
    },
    validationSchema: Step_1_validation_schema,
    onSubmit: async (values) => {
      dispatch(setWaterFormData(values));
      dispatch(nextStep());
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} noValidate className="form">
      <div className="p-7">
        <Row>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">Enter Max Depth*</label>
            <input
              type="number"
              className="form-control form-control-lg form-control-solid"
              placeholder="Max Depth"
              {...formik.getFieldProps("maxDepth")}
            />
            {formik.touched.maxDepth &&
              formik.errors.maxDepth &&
              typeof formik.errors.maxDepth === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.maxDepth}</div>
                </div>
              )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Select Max depth measure
            </label>
            <select
              className="form-control form-control-lg form-control-solid"
              {...formik.getFieldProps("maxDepthMeasure")}
            >
              <option value="">Select</option>
              <option value="feet">Feet</option>
              <option value="meter">Meter</option>
            </select>
            {formik.touched.maxDepthMeasure && formik.errors.maxDepthMeasure && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.maxDepthMeasure}
                </div>
              </div>
            )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">Enter Max length*</label>
            <input
              type="number"
              className="form-control form-control-lg form-control-solid"
              placeholder="Max length"
              {...formik.getFieldProps("maxLength")}
            />
            {formik.touched.maxLength &&
              formik.errors.maxLength &&
              typeof formik.errors.maxLength === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.maxLength}</div>
                </div>
              )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Select Max length measure
            </label>
            <select
              className="form-control form-control-lg form-control-solid"
              {...formik.getFieldProps("maxLengthMeasure")}
            >
              <option value="">Select</option>
              <option value="feet">Feet</option>
              <option value="meter">Meter</option>
              <option value="kilometer">Kilometer</option>
            </select>
            {formik.touched.maxLengthMeasure && formik.errors.maxLengthMeasure && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.maxLengthMeasure}
                </div>
              </div>
            )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">Enter Max width*</label>
            <input
              type="number"
              className="form-control form-control-lg form-control-solid"
              placeholder="Max width"
              {...formik.getFieldProps("maxWidth")}
            />
            {formik.touched.maxWidth &&
              formik.errors.maxWidth &&
              typeof formik.errors.maxWidth === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.maxWidth}</div>
                </div>
              )}
          </Col>

          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Select Max width measure
            </label>
            <select
              className="form-control form-control-lg form-control-solid"
              {...formik.getFieldProps("maxWidthMeasure")}
            >
              <option value="">Select</option>
              <option value="feet">Feet</option>
              <option value="meter">Meter</option>
              <option value="kilometer">Kilometer</option>
            </select>
            {formik.touched.maxWidthMeasure && formik.errors.maxWidthMeasure && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.maxWidthMeasure}
                </div>
              </div>
            )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">Enter Surface Area*</label>
            <input
              type="number"
              className="form-control form-control-lg form-control-solid"
              placeholder="Surface Area"
              aria-label="Max length"
              {...formik.getFieldProps("surfaceArea")}
            />
            {formik.touched.surfaceArea && formik.errors.surfaceArea && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.surfaceArea}</div>
              </div>
            )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Select surface area measure
            </label>
            <select
              className="form-control form-control-lg form-control-solid"
              {...formik.getFieldProps("surfaceAreaMeasure")}
            >
              <option value="">Select</option>
              <option value="m2">m2</option>
              <option value="km2">km2</option>
            </select>
            {formik.touched.surfaceAreaMeasure &&
              formik.errors.surfaceAreaMeasure && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.surfaceAreaMeasure}
                  </div>
                </div>
              )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter Surface Elevation*
            </label>
            <input
              type="number"
              className="form-control form-control-lg form-control-solid"
              placeholder="Surface Elevation"
              aria-label="Max length"
              {...formik.getFieldProps("surfaceElevation")}
            />
            {formik.touched.surfaceElevation && formik.errors.surfaceElevation && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.surfaceElevation}
                </div>
              </div>
            )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Select surface elevation measure
            </label>
            <select
              className="form-control form-control-lg form-control-solid"
              {...formik.getFieldProps("surfaceElevationMeasure")}
            >
              <option value="">Select</option>
              <option value="feet">Feet</option>
              <option value="meter">Meter</option>
              <option value="kilometer">Kilometer</option>
            </select>
            {formik.touched.surfaceElevationMeasure &&
              formik.errors.surfaceElevationMeasure && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.surfaceElevationMeasure}
                  </div>
                </div>
              )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">Enter Area*</label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Enter Area"
              aria-label="Name"
              {...formik.getFieldProps("area")}
            />
            {formik.touched.area &&
              formik.errors.area &&
              typeof formik.errors.area === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.area}</div>
                </div>
              )}
          </Col>
        </Row>
      </div>
      <div
        className="modal-footer"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          onClick={() => dispatch(prevStep())}
          size="lg"
          variant="secondary"
        >
          Back
        </Button>
        <Button className="ms-3" type="submit" size="lg" variant="primary">
          Save & Next
        </Button>
      </div>
    </form>
  );
};

export default Step_1;
