import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slice/auth/authSlice";
import headerReducer from "./slice/header/headerSlice";
import speciesFormReducer from "./slice/form/speciesForm";
import watersFormReducer from "./slice/form/watersForm";

const store = configureStore({
  reducer: {
    auth: authReducer,
    header: headerReducer,
    species : speciesFormReducer,
    waters : watersFormReducer
  },
});
export default store;
