import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation } from "react-query";
import { getAllFeedbackList } from "../../../api";
import useDebounce from "../../../hooks/useDebounce";
import CustomTooltip from "../../../_metronic/layout/components/common/CustomTooltip";
import { KTCard } from "../../../_metronic/helpers";
import MuiTable from "../../../components/table/MuiTable";

const FeedBack: React.FC = () => {
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: "",
  });

  const tableRef = useRef();
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);

  const {
    mutateAsync: getFeedBackListAPI,
    data: feedBackData,
    isLoading: isLoading,
  } = useMutation("feedback-list", getAllFeedbackList);

  useEffect(() => {
    getAllWaters(tableOptions);
  }, []);

  useEffect(() => {
    getAllWaters({
      ...tableOptions,
      search: debounceSearchValue ? debounceSearchValue : "",
    });
  }, [debounceSearchValue]);

  const getAllWaters = async (query: any) => {
    let response = await getFeedBackListAPI(query);
    if (response?.status) {
      setTableOptions((prevState: any) => ({
        ...prevState,
        offset: response?.data?.offset,
        limit: response?.data?.limit,
        hasNextPage: response?.data?.hasNextPage,
        hasPrevPage: response?.data?.hasPrevPage,
        totalRowCount: response?.data?.totalDocs,
      }));
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  // Define the columns for the table
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => (
        <span>
          {data?.row?.UserId?.firstName} {data?.row?.UserId?.lastName}
        </span>
      ),
    },
    {
      field: "feedbackType",
      headerName: "Type",
      flex: 0.3,
      sortable: false,
      renderCell: (data) => (
        <span>{data?.row?.feedbackType ? data?.row?.feedbackType : "N/A"}</span>
      ),
    },
    {
      field: "feedbackContent",
      headerName: "Content",
      flex: 0.6,
      sortable: false,
      renderCell: (data) => (
        <CustomTooltip
          title={
            data?.row?.feedbackContent ? (
              <h6 style={{ color: "white" }}>{data?.row?.feedbackContent}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.feedbackContent
              ? data?.row?.feedbackContent.length > 30
                ? data?.row?.feedbackContent.substring(0, 30) + "..."
                : data?.row?.feedbackContent
              : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },
    // {
    //   field: "createdAt",
    //   headerName: "Date",
    //   flex: 0.4,
    //   sortable: false,
    //   renderCell: (data) => (
    //     <CustomTooltip
    //       title={
    //         data?.row?.createdAt ? (
    //           <h6 style={{ color: "white" }}>{formatDate(data?.row?.createdAt)}</h6>
    //         ) : (
    //           "N/A"
    //         )
    //       }
    //     >
    //       <span>
    //         {data?.row?.createdAt ? formatDate(data?.row?.createdAt) : "N/A"}
    //       </span>
    //     </CustomTooltip>
    //   ),
    // },
  ];

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize === tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? "",
    };
    getAllWaters(query);
  };

  return (
    <Card className="p-6">
      <KTCard>
        <MuiTable
          columns={columns}
          isAddEnable={false}
          data={feedBackData?.data?.docs ?? []}
          tableRef={tableRef}
          loading={isLoading}
          isBackEnable={false}
          isRowClickable={true}
          handlePagination={handlePagination}
          tableOptions={tableOptions}
        />
      </KTCard>
    </Card>
  );
};

export default FeedBack;
