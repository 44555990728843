import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { addRulesAndRegulations, getRulesAndRegulations } from "../../../api";
import { snackActions } from "../../../helpers/SnackUtilsConfigurator";


const Rules_Regulations = () => {

  const [bodyText, setBodyText] = useState("");

  const { data: rulesData  } = useQuery("rules-regulations", getRulesAndRegulations);

  const {
    mutateAsync: addRulesAPI,
    isLoading: isLoading,
  } = useMutation("rules-regulation", addRulesAndRegulations);

  useEffect(() => {
    if (rulesData) {
      setBodyText(rulesData.data?.description);
    }
  }, [rulesData]);

  const handleSubmit = async () => {
    let bodyParams = {
      description: bodyText
    }
    let response = await addRulesAPI(bodyParams);
    if (response?.status) {
      snackActions.success(response?.message ?? "Rules & Regulations Updated Successfully!");
    } else {
      snackActions.error(response?.message ?? "Something went wrong!");
    }

  }

  return (
    <div>
      <div className="card mb-5 mb-xl-10 p-14">
        <ReactQuill
          theme="snow"
          placeholder="Write something..."
          modules={Rules_Regulations.modules}
          formats={Rules_Regulations.formats}
          style={{ height: "50vh" }}
          value={bodyText}
          onChange={(e) => setBodyText(e)}
        />
        <div className="d-flex justify-content-end">
          <Button variant="primary" style={{ width: "100px", marginTop: "50px" }} onClick={handleSubmit}  disabled={!bodyText || !bodyText?.replace(/<\/?[^>]+(>|$)/g, "")} color={!bodyText || !bodyText?.replace(/<\/?[^>]+(>|$)/g, "") ? "disabled" : "success"}>{isLoading ? "Loading." : "Submit"}</Button>{' '}
        </div>
      </div>
    </div>
  );
};

export default Rules_Regulations;


Rules_Regulations.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: [] }],
  ],
};
Rules_Regulations.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];