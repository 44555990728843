import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  step: 0,
  waterFormData: {
    name: "",
    image: "",
    type: "",
    Area: "",
    maxLength: "",
    maxWidth: "",
    maxDepth: "",
    surfaceArea: "" ,
    surfaceElevation: "",
    AreaMeasure: "",
    maxLengthMeasure: "",
    maxWidthMeasure: "",
    maxDepthMeasure: "",
    surfaceAreaMeasure: "",
    surfaceElevationMeasure: "",
    CountryId: "",
    Country: "",
    Region: [],
    StateId: 1,
    State: "",
    Latitude: "",
    Longitude: ""
  }
};

const watersformSlice = createSlice({
  name: "watersForm",
  initialState,
  reducers: {
    nextStep(state) {
      state.step += 1;
    },
    prevStep(state) {
      state.step -= 1;
    },
    setWaterFormData(state, action) {
      state.waterFormData = { ...state.waterFormData, ...action.payload };
    },
    resetFormData(state) {
      state.waterFormData = initialState.waterFormData;
    },
    resetStep(state) {
      state.step = 0;
    },
  },
});

export const {
  nextStep,
  prevStep,
  setWaterFormData,
  resetStep,
  resetFormData
} = watersformSlice.actions;

export default watersformSlice.reducer;
