import React, { useState } from "react";
import { Box } from "@mui/material";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { KTIcon } from "../../../_metronic/helpers";
import * as Yup from "yup";
import { useFormik } from "formik";
import { charValidate } from "../../../components/utility";
import { addCategory, uploadPicture } from "../../../api";
import { snackActions } from "../../../helpers/SnackUtilsConfigurator";
import { useMutation } from "react-query";
import { APP_URLs } from "../../../constants/appURLs";
import CloseIcon from "@mui/icons-material/Close";

const AddEditCategory = ({ show, handleClose, editData }: any) => {
  let imgUrl: string = editData?.image
    ? editData?.image[0]
      ? editData?.image[0]
      : ""
    : "";

  const [categoryImg, setCategoryImg] = useState<string>("");
  const [imgUploading, setImgUploading] = useState<boolean>(false);

  const [imgFileName, setImgFileName] = useState<string>(
    imgUrl ? imgUrl?.split("/img/")[1] : ""
  );

  const categoryValidationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    image: Yup.string().required("Image is required"),
  });

  const { mutateAsync: addCategoryAPI, isLoading: loading } = useMutation(
    "add-category",
    addCategory
  );

  const formik: any = useFormik({
    initialValues: {
      name: editData ? editData?.name : "",
      image: editData ? editData?.image : "",
    },
    validationSchema: categoryValidationSchema,
    onSubmit: async (values, { setFieldError }) => {
      if (!categoryImg && !editData?.image) {
        setFieldError("image", "Image is required");
        return;
      }
      let bodyParams = {
        id: editData ? editData?._id : undefined,
        name: values?.name ? values?.name?.replace(/^\s+|\s+$/g, "") : "",
        image: categoryImg || (editData && editData.image) || null,
      };
      let response = await addCategoryAPI(bodyParams);
      if (response?.status) {
        snackActions.success(
          response?.message ?? "Category Added Successfully!"
        );
        formik.resetForm();
        setImgFileName("");
        setCategoryImg("");
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });

  const handleChangeImageUpload = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setImgFileName(file?.name ?? "");
    formData.append("photo", file);
    if (file) {
      setImgUploading(true);
      let response = await uploadPicture(formData);
      if (response?.status) {
        setCategoryImg(response?.data?.images);
        formik.setFieldValue("image", response?.data?.images);
        setImgUploading(false);
      } else {
        snackActions.error(
          response?.message ?? "Issue in image upload, try again latter!"
        );
        setImgUploading(false);
      }
    }
  };

  const removeImg = () => {
    formik.setFieldValue("image", "");
    formik.setFieldError("image", null);
    setCategoryImg("");
    if (editData) {
      editData.image = "";
    }
    setImgFileName("");
  };

  return (
    <Box>
      <Modal
        id="kt_modal_create_app"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-500px"
        show={show}
        backdrop="static"
      >
        <div className="modal-header">
          <h2> {editData ? "Edit" : "Add"} Category</h2>
          {/* begin::Close */}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              setImgFileName("");
              setCategoryImg("");
              handleClose(false);
            }}
          >
            <KTIcon className="fs-1" iconName="cross" />
          </div>
          {/* end::Close */}
        </div>

        <div className="p-6" style={{ overflowY: "auto" }}>
          <form onSubmit={formik.handleSubmit} noValidate className="form">
            <div className="mb-6">
              <label className="form-label fw-bold">
                Name<span className="red-asterisk">*</span>
              </label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                placeholder="Name"
                aria-label="Name"
                {...formik.getFieldProps("name")}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.name}</div>
                </div>
              )}
            </div>
            <div className="mb-6">
              <label className="form-label fw-bold">
                Image upload<span className="red-asterisk">*</span>
              </label>
              <div>
                <label
                  htmlFor="category-img"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer"
                >
                  {imgFileName ? charValidate(imgFileName, 25) : "Choose File"}
                </label>
                <input
                  id="category-img"
                  type="file"
                  accept="image/*"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Upload Image"
                  aria-label="Upload Image"
                  onChange={handleChangeImageUpload}
                  style={{
                    display: "none",
                  }}
                />
                {formik.touched.image && formik.errors.image && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.image}</div>
                  </div>
                )}
                {imgUploading && (
                  <div className="d-flex justify-content-center m-5">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}

                {(categoryImg || editData?.image) && (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "1rem",
                      position: "relative",
                    }}
                  >
                    <img
                      src={`${APP_URLs.awsS3ImageURL}${
                        categoryImg || editData?.image
                      }`}
                      style={{
                        maxWidth: "200px",
                        margin: "0 auto",
                        padding: "1rem",
                        border: "1px solid #eeeeee",
                      }}
                      alt="Preview"
                    />
                    <CloseIcon className="close-icon" onClick={removeImg} />
                  </div>
                )}
              </div>
            </div>
            <div className="w-100 d-flex align-items-center justify-content-end mt-5">
              <Button
                onClick={() => {
                  formik.resetForm();
                  setImgFileName("");
                  setCategoryImg("");
                  handleClose(false);
                }}
                disabled={imgUploading}
                size="lg"
                variant="secondary"
              >
                Cancel
              </Button>
              <div className="w-15px"></div>
              <Button
                type="submit"
                size="lg"
                variant="primary"
                disabled={imgUploading}
              >
                {loading ? "Loading..." : "Submit"}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </Box>
  );
};

export default AddEditCategory;
