
import axios from "axios";
import { APP_URLs, config } from "../constants/appURLs";
import { snackActions } from "../helpers/SnackUtilsConfigurator";
import httpsClient from "./httpsClient";

//gear

const getAllCategoryList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.gear.commonEndPointForCategory}?offset=${
        query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search ?? ""}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addCategory = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.gear.commonEndPointForCategory,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const deleteCategory = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.gear.commonEndPointForCategory}/${payloadData?.id}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

//company

const getAllCompanyList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.gear.commonEndPointForCompany}?offset=${
        query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search ?? ""}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addCompany = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.gear.commonEndPointForCompany,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const deleteCompany = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.gear.commonEndPointForCompany}/${payloadData?.id}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
//product

const getAllProductList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.gear.getAllProduct}?offset=${query?.offset ?? ""}&limit=${
        query?.limit ?? ""
      }&search=${query?.search ?? ""}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addProduct = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.gear.addProduct,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {}
};

const deleteProduct = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.gear.addProduct}/${payloadData?.id}`,
      config
    );
    return data;
  } catch (error: any) {}
};

//bait

const getAllBaitList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.bait.genericBaitAPI}?offset=${query?.offset ?? ""}&limit=${
        query?.limit ?? ""
      }&search=${query?.search ?? ""}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const addEditBaits = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.bait.genericBaitAPI,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {}
};

const deleteBaits = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.bait.genericBaitAPI}/${payloadData?.id}`,
      config
    );
    return data;
  } catch (error: any) {}
};

//Species

const getAllSpeciesList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.species.genericSpeciesAPI}?offset=${
        query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search ?? ""}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addEditSpecies = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.species.addEditSpecies,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteSpecies = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.species.addEditSpecies}/${payloadData?.id}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getSpeciesById = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.species.getSpeciesDetails}/${query}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

//waters

const getWaterList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.waters.genericWaterAPI}?offset=${query?.offset ?? ""}&limit=${
        query?.limit ?? ""
      }&search=${query?.search ?? ""}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const getUnlabelledWaterList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.unlabeledWater.genericWaterAPI}?offset=${query?.offset ?? ""}&limit=${
        query?.limit ?? ""
      }&Search=${query?.search ?? ""}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const getTFTModalList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.tftModal.genericTftAPI}?offset=${query?.offset ?? ""}&limit=${
        query?.limit ?? ""
      }&Search=${query?.search ?? ""}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const uploadPictureTFLite = async (payloadData: any) => {
  try {
    const { data } = await axios.put(APP_URLs.mediaUploadTFLite, payloadData, config);
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addTFLiteAllModal = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.tfLiteModal.tfModalFilesAPI,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
//contact us
const getAllContactUsList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.contactus.getContactUsAPI}?offset=${query?.offset ?? ""}&limit=${
        query?.limit ?? ""
      }&search=${query?.search ?? ""}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
//feedback
const getAllFeedbackList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.feedback.getfeedbackAPI}?offset=${query?.offset ?? ""}&limit=${
        query?.limit ?? ""
      }&search=${query?.search ?? ""}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
//DashBoard
const getDashboardList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.dashboard.getDashboardAPI}?PostType=${query}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getAllWaterList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.waters.getAllWaterLIst}?offset=${
        query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search ?? ""}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addEditWaters = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.waters.genericWaterAPI,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const addEditUnlabeledWaters = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.put(
      APP_URLs.unlabeledWater.genericWaterAPI,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteWater = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.waters.genericWaterAPI}/${payloadData?.id}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const deleteUnlabeledWater = async (payloadData: any) => {
  let id = payloadData?.id;
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.unlabeledWater.deleteGenericWaterAPI}?id=${id}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

// Common
const getCountries = async () => {
  try {
    const { data } = await httpsClient.get(
      APP_URLs.commonEndPoints.getCountries,
      config
    );
    return data;
  } catch (error: any) {}
};

const getStates = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.commonEndPoints.getCountries}/${payloadData}`,
      config
    );
    return data;
  } catch (error: any) {}
};

const getCities = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.commonEndPoints.getCities,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addAdmin = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.post(
      APP_URLs.appUser.addAdmin,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const editAdmin = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.put(
      APP_URLs.appUser.editAdmin,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const suspendActiveAdmin = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.put(
      APP_URLs.appUser.suspendAdmin,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

//all users
const getAllUserList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.users.appAllUsersList}?offset=${query?.offset ?? ""}&limit=${
        query?.limit ?? ""
      }&search=${query?.search ?? ""}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteUsers = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.users.deleteUsers}?id=${payloadData?.id}`,
      config
    );
    return data;
  } catch (error: any) {}
};

const getFeedbackList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.users.feedback}?offset=${query?.offset ?? ""}&limit=${
        query?.limit ?? ""
      }&search=${query?.search ?? ""}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
//Post

const getPostList = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.post.genericPostAPI}?offset=${query?.offset ?? ""}&limit=${
        query?.limit ?? ""
      }&search=${query?.search ?? ""}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const deleteAdminPost = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.delete(
      `${APP_URLs.post.deletePostAPI}/${payloadData?.id}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

//about-app

const getAboutApp = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.aboutApp.genericAboutAppAPI}?offset=${
        query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search ?? ""}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addAboutApp = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.put(
      APP_URLs.aboutApp.genericAboutAppAPI,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

//terms and conditon

const getTermsCondition = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.termAndCondition.genericAboutAppAPI}?offset=${
        query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search ?? ""}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addTermsCondition = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.put(
      APP_URLs.termAndCondition.genericAboutAppAPI,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

//privacy policy

const getPrivacyPolicy = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.privacyPolicy.genericAboutAppAPI}?offset=${
        query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search ?? ""}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addPrivacyPolicy = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.put(
      APP_URLs.privacyPolicy.genericAboutAppAPI,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

//copyright policy

const getCopyRightPolicy = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.copyRightPolicy.genericCopyRightAPI}?offset=${
        query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search ?? ""}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addCopyRightPolicy = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.put(
      APP_URLs.privacyPolicy.genericAboutAppAPI,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

//Rules & regulations

const getRulesAndRegulations = async (query: any) => {
  try {
    const { data } = await httpsClient.get(
      `${APP_URLs.rulesRegulations.genericrulesRegulationsAPI}?offset=${
        query?.offset ?? ""
      }&limit=${query?.limit ?? ""}&search=${query?.search ?? ""}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addRulesAndRegulations = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.put(
      APP_URLs.rulesRegulations.genericrulesRegulationsAPI,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const uploadPicture = async (payloadData: any) => {
  try {
    const { data } = await axios.put(APP_URLs.mediaUpload, payloadData, config);
    return data;
  } catch (error: any) {
    // snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const uploadMultiPicture = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.put(APP_URLs.multiMediaUpload, payloadData, config);
    return data;
  } catch (error: any) {
  }
};

const changePassword = async (payloadData: any) => {
  try {
    const { data } = await httpsClient.put(
      APP_URLs.changeUserPassword.genericAPI,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? "Something went wrong");
  }
};

export {
  addAdmin,
  editAdmin,
  deleteAdminPost,
  getRulesAndRegulations,
  addRulesAndRegulations,
  getCities,
  getCountries,
  getAllCategoryList,
  addCategory,
  deleteCategory,
  getAllWaterList,
  getUnlabelledWaterList,
  getSpeciesById,
  getAllContactUsList,
  getDashboardList,
  getAllFeedbackList,
  addPrivacyPolicy,
  getPrivacyPolicy,
  addAboutApp,
  getAboutApp,
  getTermsCondition,
  addCopyRightPolicy,
  getCopyRightPolicy,
  addTermsCondition,
  deleteUsers,
  getPostList,
  addCompany,
  uploadMultiPicture,
  deleteCompany,
  addProduct,
  deleteProduct,
  getFeedbackList,
  getWaterList,
  getAllCompanyList,
  getAllUserList,
  getAllProductList,
  addEditBaits,
  deleteBaits,
  getStates,
  getAllBaitList,
  deleteSpecies,
  deleteWater,
  getAllSpeciesList,
  addEditSpecies,
  suspendActiveAdmin,
  uploadPicture,
  changePassword,
  addEditWaters,
  addEditUnlabeledWaters,
  deleteUnlabeledWater,
  getTFTModalList,
  uploadPictureTFLite,
  addTFLiteAllModal,
};
