import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Tooltip } from "@mui/material";
import { Button, Card } from "react-bootstrap";
import { KTCard, KTIcon } from "../../../_metronic/helpers";
import { charValidate } from "../../../components/utility";
// import { isSuperAdmin } from "../../../hooks/useUserInfo";
import MuiTable from "../../../components/table/MuiTable";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { GridColDef } from "@mui/x-data-grid";
import CustomTooltip from "../../../_metronic/layout/components/common/CustomTooltip";
import { useMutation } from "react-query";
import { deleteCategory, getAllCategoryList } from "../../../api";
import useDebounce from "../../../hooks/useDebounce";
import { APP_URLs } from "../../../constants/appURLs";
import errorFishImageJpg from "../../../_metronic/assets/errorFishImage.jpg";
import AddEditCategory from "./AddEditCategory";
import ConfirmEventPopup from "../../../_metronic/layout/components/common/ConfirmEventPopup";
import { snackActions } from "../../../helpers/SnackUtilsConfigurator";

const Category: React.FC = () => {
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: "",
  });

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const tableRef = useRef();
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);

  const {
    mutateAsync: getCategoryListAPI,
    data: categoryData,
    isLoading: isLoading,
  } = useMutation("category-list", getAllCategoryList);

  const { mutateAsync: deleteCategoryAPI, isLoading: isDeleting } = useMutation(
    "delete",
    deleteCategory
  );

  useEffect(() => {
    getCategory(tableOptions);
  }, []);

  useEffect(() => {
    getCategory({
      ...tableOptions,
      search: debounceSearchValue ? debounceSearchValue : "",
    });
  }, [debounceSearchValue]);

  const getCategory = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
        }
      | undefined
  ) => {
    let response = await getCategoryListAPI(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };

  const handleImageError = (event: any) => {
    event.target.src = errorFishImageJpg;
  };

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "Image",
      flex: 0.3,
      sortable: false,
      renderCell: (data: any) =>
        (
          <img
            style={{ height: "3rem", width: "3rem" }}
            src={`${APP_URLs.awsS3ImageURL}${data?.row?.image}`}
            onError={handleImageError}
          />
        ) ?? "N/A",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.4,
      sortable: false,
      renderCell: (data) => (
        <CustomTooltip
          title={
            data?.row?.name ? (
              <h6 style={{ color: "white" }}>{data?.row?.name}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.name ? charValidate(data?.row?.name, 30) : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.3,
      minWidth: 120,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Edit">
            <DriveFileRenameOutlineIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowAddEditModal(true);
              }}
              sx={{ fontSize: 22, cursor: "pointer", color : "#07427c" }}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteSweepIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowDeleteConfirmModal(true);
              }}
              sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
              color="error"
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? "",
    };
    getCategory(query);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    setSelectedRow("");
    if (isSubmit) {
      getCategory(tableOptions);
    }
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
    setSelectedRow("");
  };

  const confirmDelete = async () => {
    let response = await deleteCategoryAPI({ id: selectedRow?._id });
    if (response?.status) {
      snackActions.success(response?.message);
      setShowDeleteConfirmModal(false);
      setSelectedRow("");
      handleModalClose(true)
    } else {
      snackActions.error(response?.message);
    }
  };

  return (
    <Card className="p-6">
      <>
        <KTCard>
          <MuiTable
            columns={columns}
            isAddEnable={false}
            data={categoryData?.data?.docs ?? []}
            tableRef={tableRef}
            loading={isLoading}
            isBackEnable={false}
            isRowClickable={true}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
            filters={
              <Grid container spacing={2} pb={3}>
                <Grid item lg={3} md={4} xs={12}>
                  <Box>
                    <label
                      className="form-label fw-bold"
                      style={{ marginLeft: 5 }}
                    >
                      Search By Name
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Search by name"
                      aria-label="Search"
                      onChange={(e: any) =>
                        handleChangeFilters("search", e?.target?.value)
                      }
                      value={tableOptions?.search}
                    />
                  </Box>
                </Grid>
                {/* {isSuperAdmin && ( */}
                <div className="ms-auto" style={{ marginTop: 43 }}>
                  <Button
                    className="d-flex align-items-center"
                    onClick={() => setShowAddEditModal(true)}
                  >
                    <KTIcon iconName="plus" className="fs-1" />
                    Add Category
                  </Button>
                </div>
                {/* )} */}
              </Grid>
            }
          />
        </KTCard>
        {showAddEditModal && (
          <AddEditCategory
            editData={selectedRow}
            show={showAddEditModal}
            handleClose={handleModalClose}
          />
        )}
        {showDeleteConfirmModal && (
          <ConfirmEventPopup
            showPopup={showDeleteConfirmModal}
            handleClose={handleCloseDelete}
            handleConfirmed={confirmDelete}
            loading={isDeleting}
            title={"Delete Category"}
            message={"Are you sure, want to delete this Category?"}
          />
        )}
      </>
    </Card>
  );
};

export default Category;
