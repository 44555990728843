import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../_metronic/assets/ts/_utils";
import { WithChildren } from "../_metronic/helpers";
import { MasterLayout } from "../_metronic/layout/MasterLayout";
import Category from "../pages/Gear/category/Category";
import DashboardPage from "../pages/dashboard/DashboardPage";
import Company from "../pages/Gear/company";
import Product from "../pages/Gear/product";
import Baits from "../pages/Bait";
import Species from "../pages/Species";
import AnnotatedImages from "../pages/annotatedImages";
import UnlabelledWaters from "../pages/unlabelledWaters";
import TfliteModels from "../pages/tfliteModels";
import DetailsAnnotatedImage from "../pages/annotatedImages/detailsAnnotatedImage";
import Waters from "../pages/waters";
import Users from "../pages/reported-content/User";
import Post from "../pages/reported-content/Post/post";
import Feedback from "../pages/reported-content/feedback";
import AboutApp from "../pages/settings/about-app";
import TermsCondition from "../pages/settings/terms-condition";
import PrivacyPolicy from "../pages/settings/privacy-policy";
import Copyright from "../pages/settings/copyright";
import Rules_Regulations from "../pages/settings/rules-regulations";
import ContactUs from "../pages/settings/contactus";
import FeedBack from "../pages/settings/feedback";

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import("../pages/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../pages/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../pages/accounts/AccountPage"));

  const routes = [
    { pathname: "/auth/*", element: <Navigate to="/dashboard" /> }, // <----- Redirect to Dashboard after success login/registartion
    { pathname: "/dashboard", element: <DashboardPage /> },

    //gear routings
    { pathname: "/gear-category", element: <Category /> },
    { pathname: "/gear-company", element: <Company /> },
    { pathname: "/gear-product", element: <Product /> },


    // {
    //   pathname: "/terms-conditions",
    //   element: isSuperAdmin ? (
    //     <TermsConditions />
    //   ) : (
    //     <Navigate to="/error/404" />
    //   ),
    // },
    { pathname: "/baits", element: <Baits /> },
    { pathname: "/species", element: <Species /> },
    { pathname: "/annotated-images", element: <AnnotatedImages /> },
    { pathname: "/unlabelled-waters", element: <UnlabelledWaters /> },
    { pathname: "/tflite-models", element: <TfliteModels /> },
    { pathname: "/details-annotated-image/:id", element: <DetailsAnnotatedImage /> },
    { pathname: "/waters", element: <Waters /> },
    { pathname: "/contact-us", element: <ContactUs /> },
    { pathname: "/feedback", element: <FeedBack /> },
  
    
    //reported-content

    { pathname: "/users", element: <Users /> },
    { pathname: "/post", element: <Post /> },
    { pathname: "/feedback", element: <Feedback /> },

    //Settings

    { pathname: "/about-app", element: <AboutApp /> },
    { pathname: "/privacy-policy", element: <PrivacyPolicy /> },
    { pathname: "/terms-condition", element: <TermsCondition /> },
    { pathname: "/copyright-policy", element: <Copyright /> },
    { pathname: "/rules-regulation", element: <Rules_Regulations /> },
    
    { pathname: "/crafted/pages/profile/*", element: <ProfilePage /> },
    { pathname: "/crafted/pages/wizards/*", element: <WizardsPage /> },
    { pathname: "crafted/account/*", element: <AccountPage /> },
    { pathname: "*", element: <Navigate to="/dashboard" /> }, // <----- Redirect to Page not found if no route match
  ];

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {routes.map((route) => (
          <Route
            key={route.pathname}
            path={route.pathname}
            element={<SuspensedView>{route.element}</SuspensedView>}
          />
        ))}
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
