import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { addAboutApp, getAboutApp } from "../../../api";
import { snackActions } from "../../../helpers/SnackUtilsConfigurator";


const AboutApp = () => {
  const [bodyText, setBodyText] = useState("");
  const {
    data: privacyData,
  } = useQuery("terms", getAboutApp);

  const {
    mutateAsync: addAboutAppAPI,
    isLoading: isAddingPrivacy,
  } = useMutation("add-terms", addAboutApp);

  useEffect(() => {
    if (privacyData) {
      setBodyText(privacyData.data?.description);
    }
  }, [privacyData]);

  const handleSubmit = async () => {
    let bodyParams = {
      description: bodyText
    }
    let response = await addAboutAppAPI(bodyParams);
    if (response?.status) {
      snackActions.success(response?.message ?? "Privacy & Policy Updated Successfully!");
    } else {
      snackActions.error(response?.message ?? "Something went wrong!");
    }

  }

  return (
    <div>
      <div className="card mb-5 mb-xl-10 p-14">
        <ReactQuill
          theme="snow"
          placeholder="Write something..."
          modules={AboutApp.modules}
          formats={AboutApp.formats}
          style={{ height: "50vh" }}
          value={bodyText}
          onChange={(e) => setBodyText(e)}
        />
        <div className="d-flex justify-content-end">
          <Button variant="primary" style={{ width: "100px", marginTop: "50px" }} onClick={handleSubmit}  disabled={!bodyText || !bodyText?.replace(/<\/?[^>]+(>|$)/g, "")} color={!bodyText || !bodyText?.replace(/<\/?[^>]+(>|$)/g, "") ? "disabled" : "success"}>{isAddingPrivacy ? "Loading." : "Submit"}</Button>{' '}
        </div>
      </div>
    </div>
  );
};

export default AboutApp;


AboutApp.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: [] }],
  ],
};
AboutApp.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];