import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  step: 0,
  formData: {
    name: "",
    scientificName: "",
    description: "",
    image: [],
    lengthAvg: "",
    lengthMax: "",
    weightMax: "",
    weightAvg: "",
    weightAvgMeasure : "",
    weightMaxMeasure : "",
    lengthMaxMeasure : "",
    lengthAvgMeasure : "",
    foodValue: "",
    coloration: "",
    commonNames: "",
    howToCatch: "",
    season: "",
    bagLimit: "",
    regulations: "",
    gearAllowed: "",
    disclaimer: ""
  },
};

const speciesFormSlice = createSlice({
  name: "speciesForm",
  initialState,
  reducers: {
    nextStep(state) {
      state.step += 1;
    },
    prevStep(state) {
      state.step -= 1;
    },
    setSpeciesFormData(state, action) {
      state.formData = { ...state.formData, ...action.payload };
    },
    resetStep(state) {
      state.step = 0;
    },
    resetFormData(state) {
      state.formData = initialState.formData;
    }
  },
});

export const { nextStep, prevStep, setSpeciesFormData, resetStep, resetFormData } =
  speciesFormSlice.actions;

export default speciesFormSlice.reducer;
